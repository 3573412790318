import React from 'react';
import {
  Button, FormControl, MenuItem, Select, TextField,
  Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Checkbox, FormControlLabel,
} from '@material-ui/core';
import classes from './DialogForBlock.module.scss';
import dayjs from 'dayjs';
import { Cause } from '../../constants';

const date = new Date();
const dayStart = dayjs(date.setDate(date.getDate() + 1)).format('YYYY-MM-DD');

export const initDialogOptions = {
  open: false,
  id: null,
  cause: 'spam',
  comment: '',
  isLoaded: false,
  date: dayStart,
  isTemporarily: false
}

const DialogForBlock = ({ dialogOptions, setDialogOptions, causes, handleClose, handleBlock, withDate }) => {
  const handleChangeCause = (evt) => setDialogOptions({ ...dialogOptions, cause: evt.target.value });
  const handleChangeComment = (evt) => setDialogOptions({ ...dialogOptions, comment: evt.target.value });
  const handleChangeIsTemporarily = (evt) => setDialogOptions({ ...dialogOptions, isTemporarily: evt.target.checked });
  const handleChangeDate = (evt) => setDialogOptions({ ...dialogOptions, date: evt.target.value });

  return (
    <Dialog open={dialogOptions.open} onClose={handleClose}>
      <DialogTitle>Заблокировать</DialogTitle>
      <DialogContent className={classes.root}>
        { withDate && (
          <div className={classes.date}>
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" checked={dialogOptions.isTemporarily} onChange={handleChangeIsTemporarily}/>}
              label="Временно"
              labelPlacement="end"
            />
            <TextField
              size='small' variant='outlined' label="До" type="date"
              value={dialogOptions.date}
              onChange={handleChangeDate}
              disabled={!dialogOptions.isTemporarily}
              inputProps={{ min: dayStart }}
            />
          </div>
        )}
        <FormControl fullWidth className={classes.selectCause} >
          <Select value={dialogOptions.cause} onChange={handleChangeCause} variant="outlined" label='Укажите причину блокировки'>
            {causes.map((cause, index) => <MenuItem key={index} value={cause}>{Cause[cause] || cause}</MenuItem>)}
          </Select>
        </FormControl>
        { dialogOptions.cause === 'other' && (
          <TextField
            variant='outlined' label='Укажите причину' fullWidth required
            value={dialogOptions.comment} onChange={handleChangeComment}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Нет</Button>
        <div className={classes.blockBtn}>
          <Button
            onClick={handleBlock}
            color="primary"
            disabled={(dialogOptions.cause === 'other' && !dialogOptions.comment.length) || dialogOptions.isLoaded}
          >
            Да
          </Button>
          {dialogOptions.isLoaded && <CircularProgress size={40} className={classes.progress}/>}
        </div>

      </DialogActions>
    </Dialog>
  );
};

export default DialogForBlock;
