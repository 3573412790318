import { getHeaders } from '../../utils';
import { stringify } from 'query-string';
import { ActionCreator as AppActions } from '../app/app';
import NameSpace from '../name-space';

const initialState = {
  publications: [],
  tags: [],
  isLoaded: false,
  isLoadedResources: { users: false, tags: false },
  sortBy: 'popularity',
  sortDir: 'desc',
  next: null,
  causes: ['other']
};

const ActionType = {
  LOAD_PUBLICATIONS: 'LOAD_PUBLICATIONS',
  LOAD_MORE_PUBLICATIONS: 'LOAD_MORE_PUBLICATIONS',
  LOAD_TAGS: 'LOAD_TAGS',
  LOAD_CAUSES: 'LOAD_CAUSES',
  SET_LOADED: 'SET_LOADED',
  SET_LOADED_RESOURCES: 'SET_LOADED_RESOURCES',
  SET_PUBLICATIONS_STATE: 'SET_PUBLICATIONS_STATE'
};

const ActionCreator = {
  loadPublications: (publications, next, isLoadMore) => {
    return {
      type: isLoadMore ? ActionType.LOAD_MORE_PUBLICATIONS : ActionType.LOAD_PUBLICATIONS,
      payload: { publications, next }
    }
  },
  loadTags: (tags) => {
    return {
      type: ActionType.LOAD_TAGS,
      payload: tags
    }
  },
  loadCauses: (causes) => {
    return {
      type: ActionType.LOAD_CAUSES,
      payload: causes
    }
  },
  setLoaded: (isLoaded) => {
    return {
      type: ActionType.SET_LOADED,
      payload: isLoaded
    }
  },
  setLoadedResources: (isLoaded) => {
    return {
      type: ActionType.SET_LOADED_RESOURCES,
      payload: isLoaded
    }
  },
  setPublicationsState: (state) => {
    return {
      type: ActionType.SET_PUBLICATIONS_STATE,
      payload: state
    }
  }
};

const Operation = {
  fetchPublications: (limit, sortBy, sortDir, filters, cursor) => (dispatch, getState, api) => {
    const query = { limit, sort_by: sortBy, sort_dir: sortDir };
    const next = getState()[NameSpace.PUBLICATIONS].next;

    if (cursor && !next) return;

    dispatch(ActionCreator.setLoaded(true));

    if (cursor && next) {
      query.cursor = next;
    }

    for (let key in filters) {
      if (filters.hasOwnProperty(key) && filters[key]) {
        query[key] = filters[key];
      }
    }
    return api.get(`/admin/publications/feed?${stringify(query)}`, getHeaders())
      .then(
      (data) => {
        dispatch(ActionCreator.loadPublications(data.data.publications, data.data.next, cursor));
        dispatch(ActionCreator.setLoaded(false))
      },
      (err) => {
        dispatch(AppActions.dataError(
          err,
          Operation.fetchPublications, [limit, sortBy, sortDir, filters, cursor],
          'Не удалось загрузить публикации'
        ))
        dispatch(ActionCreator.setLoaded(false));
      }
    )
  },
  fetchTags: (limit, query) => (dispatch, getState, api) => {
    const params = { limit, query }
    dispatch(ActionCreator.setLoadedResources({ tags: true }))
    return api.get(`/admin/tags/search?${stringify(params)}`, getHeaders())
      .then(
        (data) => dispatch(ActionCreator.loadTags(data.data)),
        (err) => dispatch(AppActions.dataError(err, Operation.fetchTags, [limit, query]))
      ).then(() => dispatch(ActionCreator.setLoadedResources({ tags: false })))
  },
  fetchCausesForBlock: () => (dispatch, getState, api) => {
    return api.get(`publications/ban-causes`, getHeaders())
      .then(
        (data) => dispatch(ActionCreator.loadCauses(data.data.filter(it => it !== 'none'))),
        (err) => dispatch(AppActions.dataError(err, Operation.fetchCausesForBlock, []))
      )
  },
  banPublication: (id, cause, comment) => (dispatch, getState, api) => {
    return api.post(`admin/publications/${id}/ban`, { cause, comment }, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('Публикация заблокирована')),
        (err) => dispatch(AppActions.dataError(err, Operation.banPublication, [id, cause, comment]))
      )
  },
  unbanPublication: (id) => (dispatch, getState, api) => {
    return api.post(`admin/publications/${id}/unban`, {}, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('Публикация разблокирована')),
        (err) => dispatch(AppActions.dataError(err, Operation.unbanPublication, [id]))
      )
  },
  hidePublication: (id) => (dispatch, getState, api) => {
    return api.post(`admin/publications/${id}/hide`, {}, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('Публикация скрыта')),
        (err) => dispatch(AppActions.dataError(err, Operation.hidePublication, [id]))
      )
  },
  viewPublication: (id) => (dispatch, getState, api) => {
    return api.post(`admin/publications/${id}/display`, {}, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('Скрытие отменено')),
        (err) => dispatch(AppActions.dataError(err, Operation.viewPublication, [id]))
      )
  },
  inPublicPublication: (id) => (dispatch, getState, api) => {
    return api.post(`admin/publications/${id}/public`, {}, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('')),
        (err) => dispatch(AppActions.dataError(err, Operation.inPublicPublication, [id]))
      )
  },
  inPrivatePublication: (id) => (dispatch, getState, api) => {
    return api.post(`admin/publications/${id}/private`, {}, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('Доступ ограничен')),
        (err) => dispatch(AppActions.dataError(err, Operation.inPrivatePublication, [id]))
      )
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_PUBLICATIONS:
      return {
        ...state,
        publications: action.payload.publications,
        next: action.payload.next
      };
    case ActionType.LOAD_MORE_PUBLICATIONS:
      return {
        ...state,
        publications: [...state.publications, ...action.payload.publications],
        next: action.payload.next
      };
    case ActionType.LOAD_TAGS:
      return {
        ...state,
        tags: action.payload
      };
    case ActionType.LOAD_CAUSES:
      return {
        ...state,
        causes: action.payload
      };
    case ActionType.SET_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      };
    case ActionType.SET_LOADED_RESOURCES:
      return {
        ...state,
        isLoadedResources: { ...state.isLoadedResources, ...action.payload }
      };
    case ActionType.SET_PUBLICATIONS_STATE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDir: action.payload.sortDir
      };
    default:
      return state;
  }
};

export {ActionCreator, ActionType, reducer, Operation};
