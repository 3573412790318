import React from 'react';
import { connect } from "react-redux";
import { getAdmin } from '../../reducer/auth/selectors';
import classes from './AdminProfile.module.scss';
import { Button, Paper, TextField } from '@material-ui/core';
import { getDate } from '../../utils';
import is from "is_js";
import InputMask from 'react-input-mask';
import { Operation } from '../../reducer/employee/employee';
import { Operation as AuthOperation } from '../../reducer/auth/auth';
import { Role } from '../../constants';

const AdminProfile = ({ admin, editEmployee, recovery }) => {
  const [adminData, setAdminData] = React.useState(admin);

  const handleChange = (evt, field) => {
    setAdminData({ ...adminData, [field]: evt.target.value})
  }

  const formIsValid = () => {
    const phoneRegexp = /\+7 \(\d{3}\) \d{3} \d{2} \d{2}/g;
    const isPhoneValid = adminData.phone ? phoneRegexp.test(adminData.phone) : true;
    return is.email(adminData.email) && isPhoneValid;
  }

  const isChange = () => {
    return JSON.stringify(admin) === JSON.stringify(adminData) || !formIsValid();
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <header className={classes.header}>
          <h2>{admin.full_name || admin.email}</h2>
          <h3>{admin.role}</h3>
        </header>

        <form className={classes.form}>
          <div className={classes.info}>
            <TextField className={classes.input} label="ID" variant="outlined" size="small"
                       value={admin.id} InputProps={{readOnly: true}}/>
            <TextField className={classes.input} label="Зарегистрирован" variant="outlined" size="small"
                       value={getDate(admin.created_at)} InputProps={{readOnly: true}}/>
          </div>

          <TextField
            className={classes.input} label="ФИО" variant="outlined"
            value={adminData.full_name}
            onChange={(evt) => handleChange(evt, 'full_name')}
            InputProps={{readOnly: admin.role !== Role.ADMIN}}
          />
          <InputMask
            mask='+7 (999) 999 99 99'
            value={adminData.phone} onChange={(evt) => handleChange(evt, 'phone')}
          >
            {() => <TextField className={classes.input} label="Телефон" variant="outlined"
                              InputProps={{readOnly: admin.role !== Role.ADMIN}} />}
          </InputMask>
          <TextField
            className={classes.input} label="Почта" variant="outlined" required
            error={!is.email(adminData.email)}
            value={adminData.email}
            onChange={(evt) => handleChange(evt, 'email')}
            InputProps={{readOnly: admin.role !== Role.ADMIN}}
          />
          <div className={classes.buttons}>
            {admin.role === Role.ADMIN && (
              <Button variant="outlined" color="primary" disabled={isChange()} onClick={() => editEmployee(adminData)}>
                Сохранить изменения
              </Button>
            )}
            <Button
              variant="outlined" color="primary"
              disabled={!is.email(adminData.email)}
              onClick={() => recovery(adminData.email)}
            >
              Восстановить пароль
            </Button>
          </div>
        </form>
      </Paper>
      <Paper className={classes.paper2}>

      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin: getAdmin(state)
});

const mapDispatchToProps = (dispatch) => ({
  editEmployee: (data) => dispatch(Operation.editEmployee(data)),
  recovery: (email) => dispatch(AuthOperation.recovery(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);
