import history from "../../history";
import decodeJwt from 'jwt-decode';
import { AppRoute } from "../../constants";
import { getHeaders } from '../../utils';
import { ActionCreator as AppActions } from '../app/app';

const initialState = {
  isAuthenticated: false,
  admin: null,
};

const ActionType = {
  REQUIRED_AUTHORIZATION: `REQUIRED_AUTHORIZATION`,
  CHECK_AUTH: 'CHECK_AUTH',
  SET_ADMIN: 'SET_ADMIN'
};

const ActionCreator = {
  requireAuthorization: (status) => {
    if (!status) {
      localStorage.removeItem('auth');
      localStorage.removeItem('admin');
    }
    return {
      type: ActionType.REQUIRED_AUTHORIZATION,
      payload: status,
    };
  },
  checkAuth: () => {
    let status =
      !!localStorage.getItem('auth') &&
      !!localStorage.getItem('admin') &&
      !!localStorage.getItem('expirationDate');

    if (status) {
      const experationDate = new Date(JSON.parse(localStorage.getItem('expirationDate')));
      if (experationDate >= new Date()) {
        Operation.refresh();
      }
    }

    return {
      type: ActionType.CHECK_AUTH,
      payload: {
        status,
        admin: status ? JSON.parse(localStorage.getItem('admin')) : null
      }
    };
  },
  setAdmin: (admin) => {
    return {
      type: ActionType.SET_ADMIN,
      payload: admin
    }
  }
};

const Operation = {
  login: (authData) => (dispatch, getState, api) => {
    return api.post(`/admin/auth/sign-in`, {
      email: authData.email,
      password: authData.password,
    }).then(
      (data) => {
        const decodeToken = decodeJwt(data.data.access_token);
        localStorage.setItem('auth', JSON.stringify(data.data));
        localStorage.setItem('expirationDate', JSON.stringify(new Date(decodeToken.exp * 1000)));
        dispatch(Operation.getAdminProfile());
      },
      (err) => {
        let error = 'Не удалось авторизоваться, попробуйте еще...';

        if (err.response && err.response.status !== 404) {
          switch (err.response.data.error.code) {
            case 10011: error = 'Этот сотрудник заблокирован'; break
            case 10001: error = 'Пароль не подходит'; break
            default: error = 'Не удалось авторизоваться, попробуйте еще...';
          }
        }

        dispatch(AppActions.dataError(err, Operation.login, [authData], error))
      }
    )
  },
  recovery: (email) => (dispatch, getState, api) => {
    return api.post(`/admin/auth/recover`, { email })
      .then(
        () => dispatch(AppActions.dataSuccess('Запрос успешно отправлен! Проверьте почту!')),
        (err) => dispatch(AppActions.dataError(err, Operation.recovery, [email]))
      )
  },
  refresh: () => (dispatch, getState, api) => {
    const token = localStorage.getItem('auth');
    const { refresh_token } = token ? JSON.parse(token) : { refresh_token: null};
    return api.post(`/admin/auth/refresh`, {}, { headers: { Authorization: refresh_token } })
      .then(
        (data) => {
          const decodeToken = decodeJwt(data.data.access_token);
          dispatch(ActionCreator.requireAuthorization(true));
          localStorage.setItem('auth', JSON.stringify(data.data));
          localStorage.setItem('expirationDate', JSON.stringify(new Date(decodeToken.exp * 1000)));
          return true;
        },
        (err) => {
          if (err.response && err.response.status === 401) {
            dispatch(ActionCreator.requireAuthorization(false))
          } else {
            dispatch(AppActions.dataError(err))
          }
        }
      )
  },
  getAdminProfile: () => (dispatch, getState, api) => {
    return api.get(`/admin/employees/me`, getHeaders())
      .then(
        (data) => {
          dispatch(ActionCreator.setAdmin(data.data));
          localStorage.setItem('admin', JSON.stringify(data.data));
          dispatch(ActionCreator.requireAuthorization(true));
          history.push(`${AppRoute.ROOT}`);
        },
        (err) => dispatch(AppActions.dataError(
          err,
          Operation.getAdminProfile, [],
          'Не удалось загрузить профиль'
        ))
      )
  },
  confirmCreate: ({ token, password }) => (dispatch, getState, api) => {
    return api.post(`/admin/auth/sign-in/confirm`, { password, token })
      .then(
        () => dispatch(AppActions.dataSuccess('Профиль активирован!')),
        (err) => {
          dispatch(AppActions.dataError(
            err,
            Operation.confirmCreate, [{ token, password }],
            'Не удалось активировать профиль'
          ));
        }
      )
  },
  confirmRecovery: ({ token, password }) => (dispatch, getState, api) => {
    return api.put('/admin/auth/recover', { token, password })
      .then(
        () => dispatch(AppActions.dataSuccess('Пароль восстановлен!')),
        (err) => {
          dispatch(AppActions.dataError(
            err,
            Operation.confirmRecovery, [{ token, password }],
            'Не удалось восстановить пароль'
          ));
        }
      )
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUIRED_AUTHORIZATION:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case ActionType.CHECK_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload.status,
        admin: action.payload.admin
      };
    case ActionType.SET_ADMIN:
      return {
        ...state,
        admin: action.payload
      };
    default:
      return state;
  }
};

export {ActionCreator, ActionType, reducer, Operation};
