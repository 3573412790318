import {combineReducers} from "redux";
import {reducer as auth} from "./auth/auth";
import {reducer as publications} from "./publications/publications";
import {reducer as employees} from "./employee/employee";
import {reducer as app} from "./app/app";
import {reducer as users} from "./users/users";
import {reducer as complaints} from "./complaints/complaints";
import NameSpace from "./name-space.js";

export default combineReducers({
  [NameSpace.AUTH]: auth,
  [NameSpace.PUBLICATIONS]: publications,
  [NameSpace.APP_STATE]: app,
  [NameSpace.EMPLOYEES]: employees,
  [NameSpace.USERS]: users,
  [NameSpace.COMPLAINTS]: complaints
});
