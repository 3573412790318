import NameSpace from "../name-space.js";

export const getPublications = (state) => state[NameSpace.PUBLICATIONS].publications;
export const getTags = (state) => state[NameSpace.PUBLICATIONS].tags;
export const getCausesForBlock = (state) => state[NameSpace.PUBLICATIONS].causes;
export const getIsLoaded = (state) => state[NameSpace.PUBLICATIONS].isLoaded;
export const getIsLoadedResources = (state) => state[NameSpace.PUBLICATIONS].isLoadedResources;
export const getPublicationsState = (state) => ({
  sortBy: state[NameSpace.PUBLICATIONS].sortBy,
  sortDir: state[NameSpace.PUBLICATIONS].sortDir,
  next: state[NameSpace.PUBLICATIONS].next
});
