import { stringify } from 'query-string';
import { getHeaders } from '../../utils';
import { ActionCreator as AuthActions } from '../auth/auth';
import { ActionCreator as AppActions } from '../app/app';
import NameSpace from '../name-space';

const initialState = {
  columns: [
    { field: 'id', label: 'ID', isSort: false },
    { field: 'full_name', label: 'Никнейм', isSort: false },
    { field: 'phone', label: 'Телефон', isSort: false },
    { field: 'email', label: 'Почта', isSort: false },
    { field: 'created_at', label: 'Дата регистрации', isSort: false },
    { field: 'is_active', label: 'Активный', isSort: false},
    { field: 'role', label: 'Роль', isSort: false },
    { field: 'actions', label: 'Действия', isSort: false },
  ],
  employees: [],
  employee: null,
  page: 0,
  rowsPerPage: 14
};

const ActionType = {
  LOAD_EMPLOYEES: `LOAD_EMPLOYEES`,
  SET_EMPLOYEE: 'SET_EMPLOYEE',
  SET_EMPLOYEES_STATE: 'SET_EMPLOYEES_STATE'
};

const ActionCreator = {
  loadEmployees: (employees) => {
    return {
      type: ActionType.LOAD_EMPLOYEES,
      payload: employees,
    };
  },
  setEmployee: (employee) => {
    return {
      type: ActionType.SET_EMPLOYEE,
      payload: employee
    }
  },
  setEmployeesState: (state) => {
    return {
      type: ActionType.SET_EMPLOYEES_STATE,
      payload: state
    }
  }
};

const Operation = {
  fetchEmployees: (limit) => (dispatch, getState, api) => {
    const query = { limit };
    return api.get(`/admin/employees?${stringify(query)}`, getHeaders())
      .then(
        (data) => dispatch(ActionCreator.loadEmployees(data.data.employees)),
        (err) => dispatch(AppActions.dataError(
          err, Operation.fetchEmployees, [limit],
          'Не удалось загрузить список сотрудников'
        ))
      )
  },
  fetchEmployee: (id) => (dispatch, getState, api) => {
    return api.get(`/admin/employees/${id}`, getHeaders())
      .then(
        (data) => {
          dispatch(ActionCreator.setEmployee(data.data))
          return data.data;
        },
        (err) => dispatch(AppActions.dataError(
          err, Operation.fetchEmployee, [id],
          'Не удалось загрузить профиль сотрудника'
        ))
      )
  },
  banEmployee: (id) => (dispatch, getState, api) => {
    return api.post(`admin/employees/${id}/deactivate`, {}, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('Сотрудник заблокирован')),
        (err) => dispatch(AppActions.dataError(err, Operation.banEmployee, [id]))
      )
  },
  editEmployee: (data) => (dispatch, getState, api) => {
    const isAdmin = getState()[NameSpace.AUTH].admin.id === data.id;

    return api.patch(`/admin/employees/${data.id}`, data, getHeaders())
      .then(
        (data) => {
          if (isAdmin) {
            dispatch(AuthActions.setAdmin(data.data))
            localStorage.setItem('admin', JSON.stringify(data.data));
          }
          dispatch(ActionCreator.setEmployee(data.data));
          dispatch(AppActions.dataSuccess('Изменения успешно сохраненны'));
      },
        (err) => dispatch(AppActions.dataError(err, Operation.editEmployee, [data]))
    )
  },
  addEmployee: (data) => (dispatch, getState, api) => {
    return api.post(`/admin/employees`, data, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess('Токен выслан на указаную почту')),
        (err) => {
          let error = 'Не удалось добавить сотрудника';
          if (err.response && err.response.status !== 404 && err.response.data.error.code === 10007) {
            error = 'Такой сотрудник уже зарегистрирован'
          }
          dispatch(AppActions.dataError(err, Operation.addEmployee, [data], error))
        }
      )
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case ActionType.SET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload,
      };
    case ActionType.SET_EMPLOYEES_STATE:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage
      };
    default:
      return state;
  }
};

export {ActionCreator, ActionType, reducer, Operation};
