import { stringify } from 'query-string';
import { getHeaders } from '../../utils';
import { ActionCreator as AppActions } from '../app/app';
import { ActionCreator as PublicationsActions } from '../publications/publications';
import NameSpace from '../name-space';
import dayjs from 'dayjs';

const initialState = {
  columns: [
    { field: 'id', label: 'ID', isSort: false },
    { field: 'username', label: 'Никнейм', isSort: false },
    { field: 'phone', label: 'Телефон', isSort: false },
    { field: 'email', label: 'Почта', isSort: false },
    { field: 'latest_publication_datetime', label: 'Дата последнего видео', isSort: true},
    { field: 'created_at', label: 'Дата регистрации', isSort: true},
    { field: 'banned', label: 'Забанен', isSort: false},
    { field: 'publications_count', label: 'Кол-во публикаций', isSort: true },
    { field: 'actions', label: 'Действия', isSort: false },
  ],
  users: [],
  publications: [],
  user: null,
  sortBy: 'created_at',
  sortDir: 'desc',
  searchType: 'username',
  searchQuery: '',
  onlyWithPublications: false,
  page: 0,
  rowsPerPage: 14,
  next: null,
  causes: ['other'],
  isLoadedPublications: false
};

const ActionType = {
  LOAD_USERS: `LOAD_USERS`,
  LOAD_MORE_USERS: `LOAD_MORE_USERS`,
  LOAD_USER: 'LOAD_USER',
  LOAD_USERS_CAUSES: 'LOAD_USERS_CAUSES',
  SET_USER: 'SET_USER',
  SET_USER_PUBLICATIONS: 'SET_USER_PUBLICATIONS',
  SET_MORE_USER_PUBLICATIONS: 'SET_MORE_USER_PUBLICATIONS',
  SET_USERS_STATE: 'SET_USERS_STATE',
  CHANGE_USER_STATE: 'CHANGE_USER_STATE',
  SET_IS_LOADED_PUBLICATIONS: 'SET_IS_LOADED_PUBLICATIONS'
};

const ActionCreator = {
  loadUsers: (users, next, isLoadMore) => {
    return {
      type: isLoadMore ? ActionType.LOAD_MORE_USERS : ActionType.LOAD_USERS,
      payload: { users, next },
    };
  },
  setUser: (user) => {
    return {
      type: ActionType.SET_USER,
      payload: user
    }
  },
  setUserPublications: (publications, next, isLoadMore) => {
    return {
      type: isLoadMore ? ActionType.SET_MORE_USER_PUBLICATIONS : ActionType.SET_USER_PUBLICATIONS,
      payload: { publications, next }
    }
  },
  setUsersState: (state) => {
    return {
      type: ActionType.SET_USERS_STATE,
      payload: state
    }
  },
  changeUserState: (user) => {
    return {
      type: ActionType.CHANGE_USER_STATE,
      payload: user
    }
  },
  loadCauses: (causes) => {
    return {
      type: ActionType.LOAD_USERS_CAUSES,
      payload: causes
    }
  },
  setIsLoadedPublications: (isLoaded) => {
    return {
      type: ActionType.SET_IS_LOADED_PUBLICATIONS,
      payload: isLoaded
    }
  }
};

const Operation = {
  fetchUsers: (limit = 100, sortBy, sortDir, searchType, search, onlyWithPublications, cursor) => (dispatch, getState, api) => {
    const next = getState()[NameSpace.USERS].next;
    const query = {
      limit,
      "sort_by": sortBy,
      "sort_dir": sortDir,
      "with_pubs_only": onlyWithPublications ? 1 : 0
    }

    if (search) {
      query[searchType] = search;
    }

    if (cursor && next) {
      query.cursor = next;
    }

    dispatch(PublicationsActions.setLoadedResources({ users: true }))

    return api.get(`/admin/users?${stringify(query)}`, getHeaders())
      .then(
        (data) => dispatch(ActionCreator.loadUsers(data.data.users, data.data.next, cursor)),
        (err) => {
          dispatch(AppActions.dataError(
            err,
            Operation.fetchUsers, [limit, sortBy, sortDir, searchType, search, onlyWithPublications, cursor],
            'Не удалось загрузить список пользователей'
          ))
        }
      ).then(() => dispatch(PublicationsActions.setLoadedResources({ users: false })))
  },
  fetchUser: (id) => (dispatch, getState, api) => {
    return api.get(`admin/users/${id}`, getHeaders())
      .then(
        (data) => dispatch(ActionCreator.setUser(data.data)),
        (err) => {
          dispatch(AppActions.dataError(
            err, Operation.fetchUser, [id],
            'Не удалось загрузить профиль пользователя'
          ))
        }
      )
  },
  banUser: (id, cause, comment, date, isTemporarily) => (dispatch, getState, api) => {
    const formData = { cause, comment };
    if (isTemporarily) {
      formData.until = dayjs(date).unix();
    }
    return api.post(`/admin/users/${id}/ban`, formData, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess()),
        (err) => dispatch(AppActions.dataError(err, Operation.banUser, [id, cause, comment, date, isTemporarily]))
      )
  },
  unbanUser: (id) => (dispatch, getState, api) => {
    return api.post(`/admin/users/${id}/unban`, {}, getHeaders())
      .then(
        () => dispatch(AppActions.dataSuccess()),
        (err) => dispatch(AppActions.dataError(err, Operation.unbanUser, [id]))
      )
  },
  fetchPublications: (id, limit, isLoadMore) => (dispatch, getState, api) => {
    const query = { limit }
    const next = getState()[NameSpace.USERS].next;

    if (isLoadMore && !next) return;

    if (isLoadMore && next) {
      query.cursor = next;
    }
    dispatch(ActionCreator.setIsLoadedPublications(true))
    return api.get(`admin/users/${id}/publications?${stringify(query)}`, getHeaders())
      .then(
        (data) => {
          dispatch(ActionCreator.setUserPublications(data.data.publications, data.data.next, isLoadMore));
          dispatch(ActionCreator.setIsLoadedPublications(false));
        },
        (err) => {
          dispatch(AppActions.dataError(
            err, Operation.fetchPublications, [id, limit],
            'Не удалось загрузить публикации пользователя'
          ))
          dispatch(ActionCreator.setIsLoadedPublications(false));
        }
      )
  },
  fetchCausesForBlockUser: () => (dispatch, getState, api) => {
    return api.get(`users/ban-causes`, getHeaders())
      .then(
        (data) => dispatch(ActionCreator.loadCauses(data.data.filter(it => it !== 'none'))),
        (err) => dispatch(AppActions.dataError(err, Operation.fetchCausesForBlockUser, []))
      )
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_USERS:
      return {
        ...state,
        users: action.payload.users,
        next: action.payload.next
      };
    case ActionType.LOAD_MORE_USERS:
      return {
        ...state,
        users: [...state.users, ...action.payload.users],
        next: action.payload.next
      };
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ActionType.SET_USER_PUBLICATIONS:
      return {
        ...state,
        publications: action.payload.publications,
        next: action.payload.next
      };
    case ActionType.SET_MORE_USER_PUBLICATIONS:
      return {
        ...state,
        publications: [...state.publications, ...action.payload.publications],
        next: action.payload.next
      };
    case ActionType.CHANGE_USER_STATE:
      const users = state.users;
      users.splice(users.findIndex((it) => it.id === action.payload.id), 1, action.payload);

      return {
        ...state,
        users: users,
      };
    case ActionType.LOAD_USERS_CAUSES:
      return {
        ...state,
        causes: action.payload
      };
    case ActionType.SET_IS_LOADED_PUBLICATIONS:
      return {
        ...state,
        isLoadedPublications: action.payload
      };
    case ActionType.SET_USERS_STATE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDir: action.payload.sortDir,
        searchType: action.payload.searchType,
        searchQuery: action.payload.searchQuery,
        onlyWithPublications: action.payload.onlyWithPublications,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage
      };
    default:
      return state;
  }
};

export {ActionCreator, ActionType, reducer, Operation};
