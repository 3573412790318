import React, { useState } from 'react';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { getDate } from '../../../utils';
import classes from './UserRow.module.scss';
import { NavLink } from 'react-router-dom';
import { AccountBox as AccountBoxIcon, Beenhere as ReBlockIcon, Block as BlockIcon } from '@material-ui/icons';
import DialogForBlock, { initDialogOptions } from '../../../components/DialogForBlock/DialogForBlock';

const UserRow = ({ user, banUser, unbanUser, changeUserState, causesForBlock }) => {
  const [dialogOptions, setDialogOptions] = useState(initDialogOptions);

  const handleClickOpen = (id) => setDialogOptions({ ...dialogOptions, open: true, id });
  const handleClose = () => setDialogOptions(initDialogOptions);

  const handleBanUser = async () => {
    setDialogOptions({ ...dialogOptions, isLoaded: true });
    if (await banUser(dialogOptions.id, dialogOptions.cause, dialogOptions.comment, dialogOptions.date, dialogOptions.isTemporarily)) {
      changeUserState({ ...user, banned: true })
      handleClose();
    }
  }

  const handleUnbanUser = async (id) => {
    if (await unbanUser(id)) {
      changeUserState({ ...user, banned: false })
    }
  }

  return (
    <TableRow key={user.id} hover>
      <TableCell>{user.id}</TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>{user.phone}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{getDate(user.latest_publication_datetime)}</TableCell>
      <TableCell>{getDate(user.created_at)}</TableCell>
      <TableCell>{user.banned ? 'Да' : 'Нет'}</TableCell>
      <TableCell>{user.publications_count}</TableCell>
      <TableCell>
        <div className={classes.actions}>
          <Tooltip title='Профиль' placement='bottom'>
            <NavLink to={`/users/${user.id}`} className={classes.link}>
              <AccountBoxIcon/>
            </NavLink>
          </Tooltip>
          { user.banned ?
            <Tooltip title='Разблокировать' placement='bottom'>
              <button
                type='button' className={[classes.banBtn, classes.reBan]}
                onClick={() => handleUnbanUser(user.id)}
              >
                <ReBlockIcon/>
              </button>
            </Tooltip>
            :
            <Tooltip title='Заблокировать' placement='bottom'>
              <button
                type='button' className={[classes.banBtn, classes.ban]}
                onClick={() => handleClickOpen(user.id)}
              >
                <BlockIcon />
              </button>
            </Tooltip>
          }
          <DialogForBlock
            dialogOptions={dialogOptions}
            setDialogOptions={setDialogOptions}
            handleClose={handleClose}
            handleBlock={handleBanUser}
            causes={causesForBlock}
            withDate
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
