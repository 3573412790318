import React, { Fragment } from 'react';
import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import Video from '../../../components/Video/Video';
import classes from './UserPublications.module.scss';

const UserPublications = ({ publications, isLoaded, loadMore }) => {
  return (
    <Paper className={classes.root} onScroll={loadMore}>
      <div className={classes.list}>
        {publications.map((publication) => (
          <Video
            key={publication.id}
            publication={publication}
          />
        ))}
        {!publications.length && <p className={classes.message}>У пользователя еще нет публикаций</p>}
      </div>
      {isLoaded && (
        <Fragment>
          <Backdrop open={isLoaded} className={classes.backdrop}/>
          <CircularProgress color="primary" size={80} className={classes.loader} />
        </Fragment>
      )}
    </Paper>
  );
};

export default UserPublications;
