import NameSpace from "../name-space.js";

export const getActivePage = (state) => state[NameSpace.APP_STATE].activePage;
export const getAppError = (state) => state[NameSpace.APP_STATE].error;
export const getAppSuccess = (state) => state[NameSpace.APP_STATE].success;
export const getNavigation = (state) => {
  if (!state[NameSpace.AUTH].isAuthenticated) return;
  const activeRole = state[NameSpace.AUTH].admin.role;
  return state[NameSpace.APP_STATE].navigation.filter((it) => it.accept.includes(activeRole));
}
