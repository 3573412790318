const NameSpace = {
  APP_STATE: `APP_STATE`,
  AUTH: `AUTH`,
  PUBLICATIONS: `PUBLICATIONS`,
  COMPLAINTS: `COMPLAINTS`,
  EMPLOYEES: 'EMPLOYEES',
  USERS: `USERS`
};

export default NameSpace;
