import React, {Component, Fragment} from "react";
import {NavLink} from "react-router-dom";
import classes from './Drawer.module.scss';
import Backdrop from "../../Backdrop/Backdrop";
import Tooltip from '@material-ui/core/Tooltip';
import MenuToggle from "../MenuToggle/MenuToggle";

class Drawer extends Component {
  state = {
    menu: false
  };

  toggleMenuHandler = () => {
    this.setState({
      menu: !this.state.menu
    })
  };

  menuCloseHandler = () => {
    this.setState({
      menu: false
    })
  };

  renderLinks(links) {
    return links.map((link, i) => {
      const label = this.state.menu ? '' : link.label;

      return (
        <li key={i}>
          <Tooltip title={label} placement="right" >
            <NavLink
              to={link.to} exact={link.exact}
              activeClassName={classes.active}
              onClick={this.menuCloseHandler}
            >
              {this.state.menu ? link.label : link.icon}
            </NavLink>
          </Tooltip>
        </li>
      )
    })
  }

  render() {
    const cls = [
      classes.Drawer,
      this.state.menu ? classes.open : classes.close
    ];

    return (
      <Fragment>
        <nav className={cls.join(' ')}>
          <MenuToggle
            onToggle={this.toggleMenuHandler}
            isOpen={this.state.menu}
          />
          <ul>
            { this.renderLinks(this.props.menu) }
          </ul>
        </nav>
        { this.state.menu && <Backdrop onClick={this.menuCloseHandler}/>}
      </Fragment>
    )
  }
}

export default Drawer;
