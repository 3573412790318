import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, FormControlLabel, Popover } from '@material-ui/core';
import { Room as PinIcon, DateRange as DateIcon, Favorite as LikeICon, Visibility as SeesIcon } from '@material-ui/icons';
import DialogForBlock, { initDialogOptions } from '../DialogForBlock/DialogForBlock';
import { getDate } from '../../utils';
import { getCausesForBlock } from '../../reducer/publications/selectors';
import { ActionCreator, Operation as PublicationsOperation } from '../../reducer/publications/publications';
import classes from './Video.module.scss';

const Video = (props) => {
  const {
    publication, countInLine, banPublication, unbanPublication, hidePublication, setInput, viewPublication,
    causesForBlock, inPublicPublication, inPrivatePublication, withFilters, isComplaintsVideo,
    handleComplaintsAction } = props;

  const [dialogOptions, setDialogOptions] = useState(initDialogOptions);
  const [publicationState, setPublicationState] = useState({
    banned: publication.banned,
    hidden: publication.hidden,
    isPublic: publication.is_public
  });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setPublicationState({
      banned: publication.banned,
      hidden: publication.hidden,
      isPublic: publication.is_public
    })
  }, [publication])

  const handleClickOpen = () => setDialogOptions({ ...dialogOptions, open: true, id: publication.id });
  const handleClose = () => setDialogOptions(initDialogOptions);

  const handleBanPublications = async () => {
    setDialogOptions({ ...dialogOptions, isLoaded: true });
    if (await banPublication(dialogOptions.id, dialogOptions.cause, dialogOptions.comment)) {
      handleClose();
      setPublicationState({ ...publicationState, banned: true})
    }
  }

  const handleUnbanPublication = async () => {
    if (await unbanPublication(publication.id)) {
      setPublicationState({ ...publicationState, banned: false})
    }
  }

  const handleHidePublication = async () => {
    if (await hidePublication(publication.id)) {
      setPublicationState({ ...publicationState, hidden: true})
    }
  }

  const handleViewPublication = async () => {
    if (await viewPublication(publication.id)) {
      setPublicationState({ ...publicationState, hidden: false})
    }
  }

  const handleChangeIsPublic = async () => {
    const handler = publicationState.isPublic ? inPrivatePublication : inPublicPublication;
    if (await handler(publication.id)) {
      setPublicationState({ ...publicationState, isPublic: !publicationState.isPublic})
    }
  }

  const handleClick = (evt) => setAnchorEl(evt.currentTarget);
  const handleCloseDescription = () => setAnchorEl(null);

  const handleSetInput = (value, field) => {
    if (!withFilters) return;
    setInput(value, field);
  }

  return (
    <div className={classes.root} style={{width: `${100/countInLine}%`}}>
      <div className={classes.badges}>
        {publicationState.banned && <p className={classes.badge}>Заблокировано</p>}
        {publicationState.hidden && <p className={classes.badge}>Скрыто</p>}
      </div>
      <video className={classes.video} poster={publication.thumbnail_url} controls controlsList={'nodownload'}>
        <source src={publication.video_url}/>
      </video>
      <div className={classes.info}>
        <p className={classes.tags}>
          {publication.tags.length > 0
            ? publication.tags.map((tag, index) => (
              <span
                key={index}
                className={classes.tag}
                onClick={() => handleSetInput([tag], 'tags')}
              >
                #{tag}
              </span>)) : ''}</p>
        <div className={classes.localDateBox}>
          <div className={classes.local}>
            <PinIcon/>
            <div>
              <span onClick={() => handleSetInput(publication.country, 'country')}>{`${publication.country}`}</span>
              <span onClick={() => handleSetInput(publication.city, 'city')}>{` ${publication.city}`}</span>
            </div>
          </div>
          <p className={classes.date} onClick={() => handleSetInput(publication.created_at, 'date')}>
            <DateIcon/>
            <span>{getDate(publication.created_at, 'DD.MM.YYYY')}</span>
          </p>
        </div>
        { publication.description.length > 35
          ? <Button size='small' fullWidth variant='outlined' onClick={handleClick} className={classes.descriptionBtn}>
              {publication.description.slice(0, 35)} ...
            </Button>
          : <p className={classes.description}>{publication.description}</p>}
        <Popover
          open={!!anchorEl} anchorEl={anchorEl}
          onClose={handleCloseDescription}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <p className={classes.descriptionPopover}>{publication.description}</p>
        </Popover>
        <div className={classes.info}>
          <p className={classes.likes}>
            <LikeICon/>
            <span>{publication.popularity.toFixed(2)}</span>
          </p>
          <p className={classes.sees}>
            <SeesIcon/>
            <span>{publication.views_count}</span>
          </p>
          <p className={classes.user} onClick={() => handleSetInput(publication.username, 'user')}>
            @{publication.username}
          </p>
        </div>
        {isComplaintsVideo ?
          <div className={classes.actions}>
            <Button size='small' variant="outlined" onClick={() => handleComplaintsAction(publication.id, 'accepted')}>Заблокировать видео</Button>
            <Button size='small' variant="outlined" onClick={() => handleComplaintsAction(publication.id, 'rejected')}>Отклонить жалобу</Button>
          </div> :
          <div>
            <FormControlLabel
              className={classes.check}
              control={
                <Checkbox
                  checked={publicationState.isPublic} onChange={handleChangeIsPublic}
                  name="isPublic" color="primary"
                />
              }
              label="Доступно для неавторизованных"
            />
            <div className={classes.actions}>
              { publicationState.banned ? (
                <Button size='small' variant="outlined" onClick={handleUnbanPublication}>Разблокировать</Button>
              ) : (
                <Button size='small' variant="outlined" onClick={handleClickOpen}>Заблокировать</Button>
              )}

              { publicationState.hidden ? (
                <Button size='small' variant="outlined" onClick={handleViewPublication}>Показать</Button>
              ) : (
                <Button size='small' variant="outlined" onClick={handleHidePublication}>Скрыть</Button>
              )}
            </div>
          </div>
        }
      </div>
      <DialogForBlock
        dialogOptions={dialogOptions}
        setDialogOptions={setDialogOptions}
        handleClose={handleClose}
        handleBlock={handleBanPublications}
        causes={causesForBlock}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  causesForBlock: getCausesForBlock(state)
});

const mapDispatchToProps = (dispatch) => ({
  banPublication: (id, cause, comment) => dispatch(PublicationsOperation.banPublication(id, cause, comment)),
  unbanPublication: (id) => dispatch(PublicationsOperation.unbanPublication(id)),
  hidePublication: (id) => dispatch(PublicationsOperation.hidePublication(id)),
  viewPublication: (id) => dispatch(PublicationsOperation.viewPublication(id)),
  setPublicationsState: (state) => dispatch(ActionCreator.setPublicationsState(state)),
  getCausesForBlock: () => dispatch(PublicationsOperation.fetchCausesForBlock()),
  inPublicPublication: (id) => dispatch(PublicationsOperation.inPublicPublication(id)),
  inPrivatePublication: (id) => dispatch(PublicationsOperation.inPrivatePublication(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
