import NameSpace from "../name-space.js";

export const getUsers = (state) => state[NameSpace.USERS].users;
export const getUser = (state) => state[NameSpace.USERS].user;
export const getPublicationsByUser = (state) => state[NameSpace.USERS].publications;
export const getColumns = (state) => state[NameSpace.USERS].columns;
export const getCausesForBlockUser = (state) => state[NameSpace.USERS].causes;
export const getIsLoadedPublications = (state) => state[NameSpace.USERS].isLoadedPublications;
export const getUsersState = (state) => ({
  sortBy: state[NameSpace.USERS].sortBy,
  sortDir: state[NameSpace.USERS].sortDir,
  searchType: state[NameSpace.USERS].searchType,
  searchQuery: state[NameSpace.USERS].searchQuery,
  onlyWithPublications: state[NameSpace.USERS].onlyWithPublications,
  page: state[NameSpace.USERS].page,
  rowsPerPage: state[NameSpace.USERS].rowsPerPage
});
