import React from "react";
import MenuIcon from '@material-ui/icons/Dehaze';
import classes from './MenuToggle.module.scss';

const MenuToggle = ({isOpen, onToggle}) => {
  const cls = [
    classes.root,
    isOpen && classes.open
  ];

  return (
    <button className={cls.join(' ')}  onClick={onToggle}>
      <MenuIcon/>
    </button>
  )
};

export default MenuToggle;
