import React from 'react';
import classes from './Notification.module.scss';
import ErrorIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';

const Notification = ({children, type = 'info'}) => {
  const cls = [
    classes.root,
    classes[type]
  ];

  const icons = {
    error: <ErrorIcon/>,
    success: <CheckIcon/>,
    info: <InfoIcon/>,
  }

  return (
    <p className={cls.join(' ')}>
      {icons[type]}
      <span>{children}</span>
    </p>
  );
};

export default Notification;
