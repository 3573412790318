import DashboardIcon from "@material-ui/icons/Dashboard";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import UsersIcon from "@material-ui/icons/Group";
import EmployeeIcon from "@material-ui/icons/RecentActors";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import React from "react";
import { AppRoute } from "../../constants";
import { Operation as AuthOperation } from '../auth/auth';

const initialState = {
  navigation: [
    { to: AppRoute.ROOT, label: 'Главная',  exact: true, icon: <DashboardIcon />,
      accept: ['admin', 'moderator', 'manager'] },
    { to: AppRoute.PUBLICATIONS, label: 'Лента видео',  exact: false, icon: <VideoLibraryIcon />,
      accept: ['admin', 'moderator', 'manager'] },
    { to: AppRoute.USERS, label: 'Пользователи',  exact: false, icon: <UsersIcon />,
      accept: ['admin', 'moderator'] },
    { to: AppRoute.EMPLOYEES, label: 'Сотрудники',  exact: false, icon: <EmployeeIcon />,
      accept: ['admin'] },
    { to: AppRoute.COMPLAINTS, label: 'Жалобы',  exact: false, icon: <AnnouncementIcon />,
      accept: ['admin', 'moderator', 'manager'] },
    { to: AppRoute.LOGOUT, label: 'Выйти',  exact: false, icon: <LogoutIcon />,
      accept: ['admin', 'moderator', 'manager'] }
  ],
  activePage: '',
  error: null,
  success: null
};

const ActionType = {
  CHANGE_PAGE: 'CHANGE_PAGE',
  SET_APP_ERROR: 'SET_APP_ERROR',
  SET_APP_SUCCESS: 'SET_APP_SUCCESS'
};

const ActionCreator = {
  changeActivePage: (activePage) => {
    return {
      type: ActionType.CHANGE_PAGE,
      payload: activePage
    }
  },
  setError: (err) => {
    return {
      type: ActionType.SET_APP_ERROR,
      payload: err
    }
  },
  setSuccess: (message) => {
    return {
      type: ActionType.SET_APP_SUCCESS,
      payload: message
    }
  },
  dataSuccess: (message) => {
    return ((dispatch) => {
      dispatch(ActionCreator.setSuccess(message));
      setTimeout(() => dispatch(ActionCreator.setSuccess(null)), 3000);
      return true;
    })
  },
  dataError: (err, action = () => {}, actionArguments = [], error = 'Что то пошло не так!') => {
    const response = err.response;

    if (response && response.status === 401) {
      return async (dispatch) => {
        if (await dispatch(AuthOperation.refresh())) {
          dispatch(action(...actionArguments));
        }
      };
    }

    return ((dispatch) => {
      dispatch(ActionCreator.setError(error));
      setTimeout(() => dispatch(ActionCreator.setError(null)), 3000);
      return false;
    })
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CHANGE_PAGE:
      return {
        ...state,
        activePage: action.payload
      };
    case ActionType.SET_APP_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case ActionType.SET_APP_SUCCESS:
      return {
        ...state,
        success: action.payload
      };
    default:
      return state;
  }
};

export {ActionCreator, ActionType, reducer};
