export const Titles = {
  '/publications': 'Лента видео',
  '/users': 'Пользователи',
  '/employees': 'Сотрудники',
  '/admin': 'Профиль',
  '/complaints': 'Жалобы'
}

export const AppRoute = {
  ROOT: `/`,
  AUTH: `/auth`,
  PUBLICATIONS: `/publications`,
  USERS: `/users`,
  USER_PROFILE: '/users/:id',
  ADMIN_PROFILE: '/admin',
  EMPLOYEES: `/employees`,
  EMPLOYEE_PROFILE: `/employees/:id`,
  LOGOUT: `/logout`,
  COMPLAINTS: '/complaints'
};

export const Role = {
  ADMIN: 'admin',
  MODERATOR: 'moderator',
  MANAGER: 'manager'
}

export const Cause = {
  spam: 'Спам',
  insult: 'Оскорбление',
  adult_material: 'Материал для взрослых',
  child_pornography: 'Детская порнография',
  drugs_propaganda: 'Пропаганда наркотиков',
  arms_sale: 'Продажа оружия',
  violence: 'Насилие',
  appeal_for_suicide: 'Призыв к суициду',
  appeal_for_bullying: 'Призыв к травле',
  cruelty_to_animals: 'Жестокое обращение с животными',
  false_representation: 'Введение в заблуждение',
  fraud: 'Мошенничество',
  extremism: 'Экстремизм',
  incitement_to_racial_hatred: 'Разжигание межрасовых конфликтов',
  incitement_to_religious_hatred: 'Разжигание религиозных конфликтов',
  author_is_banned: 'Автор заблокирован',
  other: 'Другое',
}
