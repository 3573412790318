import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getDate, getCommaString } from "../../../utils";
import classes from "./UserCard.module.scss";

const UserCard = ({ user, banUser, unbanUser }) => {
  return (
    <Paper className={classes.root}>
        <header className={classes.header}>
          <h2>{user.username}</h2>
          {user.banned && <span className={classes.badge}>Заблокирован{user.banned_until !== 0 && ` до ${getDate(user.banned_until)}`}</span>}
        </header>
        <Table>
          <TableHead />
          <TableBody>
            <TableRow>
              <TableCell component="th">ID:</TableCell>
              <TableCell>{user.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Телефон:</TableCell>
              <TableCell>{user.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Почта:</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Зарегистрирован:</TableCell>
              <TableCell>{getDate(user.created_at)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Количество публикаций:</TableCell>
              <TableCell>{user.publications_count}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Дата последней публикации:</TableCell>
              <TableCell>{getDate(user.latest_publication_datetime)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Заблокированные пользователи:</TableCell>
              <TableCell>{getCommaString(user.blocked_users, 'username')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <footer className={classes.footer}>
          <Button
            className={user.banned ? classes.reBanBtn : classes.banBtn}
            color='inherit' variant="outlined"
            onClick={user.banned ? unbanUser : banUser}
          >
            { user.banned ? 'Разблокировать' : 'Заблокировать' }
          </Button>
        </footer>
      </Paper>
  );
};

export default UserCard;
