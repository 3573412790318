import React, { useEffect, useState, Fragment } from 'react';
import dayjs from 'dayjs';
import { connect } from "react-redux";
import { getDate } from '../../utils';
import { ActionCreator, Operation as PublicationsOperation } from '../../reducer/publications/publications';
import { Operation } from '../../reducer/users/users';
import {
  getIsLoaded, getIsLoadedResources, getPublications, getPublicationsState, getTags,
} from '../../reducer/publications/selectors';
import { getUsers } from '../../reducer/users/selectors';
import { Backdrop, Button, CircularProgress, Paper } from '@material-ui/core';
import Video from '../../components/Video/Video';
import Actions from './Actions/Actions';
import Filters from './Filters/Filters';
import classes from './Publications.module.scss';

const Publications = (props) =>  {
  const {
    publications, users, tags, fetchPublications, isLoaded,
    publicationsState, setPublicationsState, fetchUsers, fetchTags, isLoadedResources, getCausesForBlock
  } = props;
  const { sortBy, sortDir, next } = publicationsState;
  const date = new Date();
  const dayNow = dayjs(date).format('YYYY-MM-DD');

  const initialFilters = {
    tags: [],
    user: null,
    userBy: 'username',
    country: '',
    city: '',
    dateStart: '',
    dateEnd: ''
  }
  const [filters, setFilters] = useState(initialFilters)

  const getFiltersForBackend = () => ({
    tags: filters.tags || null,
    user_name: filters.user
      ? (filters.userBy !== 'username'
        ? users.find(user => user[filters.userBy] === filters.user).username
        : filters.user)
      : null,
    country: filters.country,
    city: filters.city,
    date_from: filters.dateStart ? dayjs(filters.dateStart).unix() : null,
    date_to: filters.dateEnd ? dayjs(filters.dateEnd).add(23, 'hour').add(59, 'minute').unix() : null
  })

  const handleFilter = () => fetchPublications(9, sortBy, sortDir, getFiltersForBackend());

  const handleResetFilters = () => setFilters(initialFilters);
  const setInput = (value, field) => {
    if (field === 'date') {
      const date = getDate(value, 'YYYY-MM-DD');
      setFilters({ ...filters, dateStart: date, dateEnd: date});
    } else if (field === 'user') {
      setFilters({ ...filters, user: value, userBy: 'username'});
    } else {
      setFilters({ ...filters, [field]: value});
    }
  }

  useEffect(() => handleFilter(), [sortBy, sortDir]);

  useEffect(() => getCausesForBlock(), [getCausesForBlock]);

  const loadMore = () => {
    fetchPublications(6, sortBy, sortDir, getFiltersForBackend(), true);
  }

  return (
    <div className={classes.root}>
      <Actions
        publicationsState={publicationsState}
        setPublicationsState={setPublicationsState}
        isLoaded={isLoaded}
        onRefresh={handleFilter}
      />

      <Paper className={classes.paper}>
        <div className={classes.publications}>
          {publications.map((publication) => (
            <Video
              key={publication.id}
              publication={publication}
              countInLine={3}
              setInput={setInput}
              withFilters
            />
          ))}
          {isLoaded && (
            <Fragment>
              <Backdrop open={isLoaded} className={classes.backdrop}/>
              <CircularProgress color="primary" size={80} className={classes.loader} />
            </Fragment>
          )}
          {next && <Button fullWidth onClick={loadMore}>Load More</Button>}
        </div>
        <Filters
          filters={filters}
          users={users}
          tags={tags}
          fetchUsers={fetchUsers}
          fetchTags={fetchTags}
          setFilters={setFilters}
          isLoaded={isLoaded}
          isLoadedResources={isLoadedResources}
          dayNow={dayNow}
          handleReset={handleResetFilters}
          handleFilter={handleFilter}
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  publications: getPublications(state),
  users: getUsers(state),
  tags: getTags(state),
  isLoaded: getIsLoaded(state),
  isLoadedResources: getIsLoadedResources(state),
  publicationsState: getPublicationsState(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPublications: (limit, sortBy, sortDir, filters, cursor) => {
    dispatch(PublicationsOperation.fetchPublications(limit, sortBy, sortDir, filters, cursor))
  },
  fetchUsers: (limit, sortBy, sortDir, searchType, search) => {
    dispatch(Operation.fetchUsers(limit, sortBy, sortDir, searchType, search))
  },
  fetchTags: (limit, query) => dispatch(PublicationsOperation.fetchTags(limit, query)),
  getCausesForBlock: () => dispatch(PublicationsOperation.fetchCausesForBlock()),
  setPublicationsState: (state) => dispatch(ActionCreator.setPublicationsState(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Publications);
