import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useDebounce } from "../../utils";
import { getCausesForBlockUser, getColumns, getUsers, getUsersState } from '../../reducer/users/selectors';
import { ActionCreator as UserActions, Operation as UsersOperation } from "../../reducer/users/users";
import {
  Table, TableBody, TableCell, TableRow,
  Paper, TextField, Select, MenuItem, FormControl, FormControlLabel, Checkbox, IconButton,
} from '@material-ui/core';
import classes from './Users.module.scss';
import EnhancedTableHead from '../../components/EnhancedTableHead/EnhancedTableHead';
import UserRow from './UserRow/UserRow';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

const Users = (props) => {
  const {
    fetchUsers, users, columns, banUser, unbanUser, setUsersState, usersState, changeUserState,
    getCausesForBlockUser, causesForBlock
  } = props;
  const { sortBy, sortDir, searchType, searchQuery, onlyWithPublications, page, rowsPerPage } = usersState;

  const debouncedSearch = useDebounce(searchQuery, 500);
  const debounceChangeOnlyWithPublications = useDebounce(onlyWithPublications, 500);

  useEffect(() => getCausesForBlockUser(), [getCausesForBlockUser]);

  useEffect(() => {
    fetchUsers(rowsPerPage * 2, sortBy, sortDir, searchType, debouncedSearch, debounceChangeOnlyWithPublications);
    setUsersState({ ...usersState, page: 0 })
  }, [debouncedSearch, sortBy, sortDir, rowsPerPage, debounceChangeOnlyWithPublications]);

  useEffect(() => {
    return () => setUsersState({ ...usersState, page: 0});
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDir === 'asc';
    setUsersState({
      ...usersState,
      sortDir: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  };

  const handleChangePage = (newPage) => {
    if (newPage < 0 || users.length <= newPage * rowsPerPage) return;
    if (page < newPage && users.length === newPage * rowsPerPage + rowsPerPage) {
      fetchUsers(rowsPerPage * 2, sortBy, sortDir, searchType, debouncedSearch, onlyWithPublications, true)
    }
    setUsersState({ ...usersState, page: newPage});
  }

  const handleSearch = (evt) => {
    if (searchType === 'id') {
      setUsersState({ ...usersState, searchQuery: evt.target.value.replace(/[^0-9]/g, '') });
    } else {
      setUsersState({ ...usersState, searchQuery: evt.target.value });
    }
  }
  const handleChangeSearchType = (evt) => {
    setUsersState({
      ...usersState,
      searchType: evt.target.value,
      searchQuery: ''
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <FormControl size='small' placeholder={'searchType'} required className={classes.searchType}>
          <Select value={searchType} onChange={handleChangeSearchType} variant="outlined">
            <MenuItem value='id'>По ID</MenuItem>
            <MenuItem value='username'>По никнейму</MenuItem>
            <MenuItem value='email'>По почте</MenuItem>
            <MenuItem value='phone'>По телефону</MenuItem>
          </Select>
        </FormControl>
        <TextField
          size='small' label="Найти пользователя" type="search" variant="outlined"
          onChange={handleSearch} value={searchQuery}
        />
        <FormControlLabel
          className={classes.check}
          control={
            <Checkbox
              checked={onlyWithPublications}
              onChange={(evt) => setUsersState({ ...usersState, onlyWithPublications: evt.target.checked })}
              name="onlyWithPublications"
              color="primary"
            />
          }
          label="Только с публикациями"
        />
      </div>

      <Paper className={classes.paper}>
        <Table size='small'>
          <EnhancedTableHead order={sortDir} orderBy={sortBy} onRequestSort={handleRequestSort} columns={columns}/>
          <TableBody>
            {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
              <UserRow
                key={user.id}
                user={user}
                banUser={banUser}
                unbanUser={unbanUser}
                causesForBlock={causesForBlock}
                changeUserState={changeUserState}
              />
            ))}
            {emptyRows > 0 && <TableRow style={{ height: 37 * emptyRows }}><TableCell colSpan={9} /></TableRow>}
          </TableBody>
        </Table>
        <div className={classes.pagination}>
          <IconButton color="primary" onClick={() => handleChangePage(page - 1)} disabled={page === 0}>
            <NavigateBefore />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleChangePage(page + 1)}
            disabled={users.length <= (page + 1) * rowsPerPage}
          >
            <NavigateNext />
          </IconButton>
        </div>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  users: getUsers(state),
  columns: getColumns(state),
  usersState: getUsersState(state),
  causesForBlock: getCausesForBlockUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (limit, sortBy, sortDir, searchType, search, onlyWithPublications, cursor) => {
    dispatch(UsersOperation.fetchUsers(limit, sortBy, sortDir, searchType, search, onlyWithPublications, cursor));
  },
  banUser: (id, cause, comment, date, isTemporarily) => {
    return dispatch(UsersOperation.banUser(id, cause, comment, date, isTemporarily));
  },
  unbanUser: (id) => dispatch(UsersOperation.unbanUser(id)),
  setUsersState: (state) => dispatch(UserActions.setUsersState(state)),
  changeUserState: (user) => dispatch(UserActions.changeUserState(user)),
  getCausesForBlockUser: () => dispatch(UsersOperation.fetchCausesForBlockUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);
