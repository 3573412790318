import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
  getCausesForBlockUser,
  getIsLoadedPublications,
  getPublicationsByUser,
  getUser,
} from '../../reducer/users/selectors';
import { ActionCreator as AppActions } from "../../reducer/app/app";
import { ActionCreator, Operation as UserOperation } from '../../reducer/users/users';
import UserPublications from "./UserPublications/UserPublications";
import UserCard from "./UserCard/UserCard";
import classes from './UserProfile.module.scss';
import { Operation as PublicationsOperation } from '../../reducer/publications/publications';
import DialogForBlock, { initDialogOptions } from '../../components/DialogForBlock/DialogForBlock';

const UserProfile = (props) => {
  const {
    user, publications, match,
    changePage, fetchUser, fetchPublications, setUser, setUserPublications,
    banUser, unbanUser, getCausesForBlockUser, getCausesForBlockPublications,
    causesForBlockUser, isLoadedPublications
  } = props;

  const [dialogOptions, setDialogOptions] = useState(initDialogOptions);

  useEffect(() => {
    getCausesForBlockUser();
    getCausesForBlockPublications();
  }, [getCausesForBlockUser, getCausesForBlockPublications]);

  useEffect(() => {
    changePage('Профиль пользователя');
    fetchUser(match.params.id);
    fetchPublications(match.params.id, 10);

    return () => {
      changePage('');
      setUser(null);
      setUserPublications([]);
    }
  }, [changePage, fetchPublications, fetchUser, match.params.id, setUser, setUserPublications])

  const handleClickOpen = (id) => setDialogOptions({ ...dialogOptions, open: true, id });
  const handleClose = () => setDialogOptions(initDialogOptions);

  const handleToggleBan = async (id, handler) => {
    if (await handler(id, dialogOptions.cause, dialogOptions.comment, dialogOptions.date, dialogOptions.isTemporarily)) {
      fetchUser(id);
      handleClose();
    }
  }

  const handleLoadMore = (evt) => {
    const { scrollTop, clientHeight, scrollHeight } = evt.target;

    if (scrollTop + clientHeight === scrollHeight && !isLoadedPublications) {
      fetchPublications(match.params.id, 10, true);
    }
  }

  return user && (
    <div className={classes.root}>
      <UserCard
        user={user}
        banUser={() => handleClickOpen(user.id)}
        unbanUser={() => handleToggleBan(user.id, unbanUser)}
      />
      <UserPublications
        publications={publications}
        isLoaded={isLoadedPublications}
        loadMore={handleLoadMore}
      />
      <DialogForBlock
        dialogOptions={dialogOptions}
        setDialogOptions={setDialogOptions}
        handleClose={handleClose}
        handleBlock={() => handleToggleBan(user.id, banUser)}
        causes={causesForBlockUser}
        withDate
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  publications: getPublicationsByUser(state),
  causesForBlockUser: getCausesForBlockUser(state),
  isLoadedPublications: getIsLoadedPublications(state)
});

const mapDispatchToProps = (dispatch) => ({
  changePage: (title) => dispatch(AppActions.changeActivePage(title)),
  getCausesForBlockUser: () => dispatch(UserOperation.fetchCausesForBlockUser()),
  getCausesForBlockPublications: () => dispatch(PublicationsOperation.fetchCausesForBlock()),
  banUser: (id, cause, comment, date, isTemporarily) => dispatch(UserOperation.banUser(id, cause, comment, date, isTemporarily)),
  unbanUser: (id) => dispatch(UserOperation.unbanUser(id)),
  fetchPublications: (id, limit, isLoadMore) => dispatch(UserOperation.fetchPublications(id, limit, isLoadMore)),
  setUserPublications: (publications) => dispatch(ActionCreator.setUserPublications(publications)),
  fetchUser: (id) => dispatch(UserOperation.fetchUser(id)),
  setUser: (user) => dispatch(ActionCreator.setUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
