import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { AppRoute } from "./constants";
import {getAdmin, getAuthorizationStatus} from "./reducer/auth/selectors";
import Layout from "./containers/Layout/Layout";
import Auth from "./containers/Auth/Auth";
import Dashboard from "./containers/Dashboard/Dashboard";
import Logout from "./components/logout/logout";
import VideoList from "./containers/Publications/Publications";
import Employees from "./containers/Employees/Employees";
import Users from "./containers/Users/Users";
import UserProfile from "./containers/UserProfile/UserProfile";
import AdminProfile from "./containers/AdminProfile/AdminProfile";
import EmployeeProfile from './containers/EmployeeProfile/EmployeeProfile';
import Complaints from "./containers/Complaints/Complaints";

class App extends Component {
  render() {
    const { isAuthenticated, admin } = this.props;
    let routes = [
      { path: AppRoute.AUTH, component: Auth, exact: false, accept: ['admin', 'moderator', 'manager'] }
    ]

    if (isAuthenticated) {
      routes = [
        { path: AppRoute.ROOT, component: Dashboard, exact: true, accept: ['admin', 'moderator', 'manager'] },
        { path: AppRoute.PUBLICATIONS, component: VideoList, exact: true, accept: ['admin', 'moderator', 'manager'] },
        { path: AppRoute.USERS, component: Users, exact: true, accept: ['admin', 'moderator'] },
        { path: AppRoute.USER_PROFILE, component: UserProfile, exact: true, accept: ['admin', 'moderator'] },
        { path: AppRoute.ADMIN_PROFILE, component: AdminProfile, exact: true, accept: ['admin', 'moderator', 'manager'] },
        { path: AppRoute.EMPLOYEES, component: Employees, exact: true, accept: ['admin'] },
        { path: AppRoute.EMPLOYEE_PROFILE, component: EmployeeProfile, exact: true, accept: ['admin'] },
        { path: AppRoute.COMPLAINTS, component: Complaints, exact: true, accept: ['admin', 'moderator', 'manager'] },
        { path: AppRoute.LOGOUT, component: Logout, exact: true, accept: ['admin', 'moderator', 'manager'] },
      ]
    }

    return (
      <div className="App">
        <Layout>
          <Switch>
            { routes
              .filter((it) => {
              if (!isAuthenticated) return true;
              return it.accept.includes(admin.role);
            })
              .map((route) => (
              <Route key={route.path} path={ route.path } exact={route.exact} component={ route.component }/>
            ))}
            <Redirect to={ isAuthenticated ? AppRoute.ROOT : AppRoute.AUTH } />
          </Switch>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: getAuthorizationStatus(state),
    admin: getAdmin(state)
  }
};

export default withRouter(connect(mapStateToProps)(App));
