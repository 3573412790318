import React from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { getNavigation } from '../../reducer/app/selectors';
import { Paper } from '@material-ui/core';
import classes from './Dashboard.module.scss';

const Dashboard = ({navigation}) => {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div>
          <h1 className={classes.title}>Welcome to administration panel "Trumpline"</h1>
        </div>
        <nav className={classes.navigation}>
          {navigation.filter(it => it.label !== 'Главная' && it.label !== 'Выйти').map((link, index) => {
            return (
              <div className={classes.tab} key={index}>
                <NavLink to={link.to} exact={link.exact}>{link.label}{link.icon}</NavLink>
              </div>
            )
          })}
          <div className={classes.tab}>
            <NavLink to='/' exact>Онбординг (2 релиз)</NavLink>
          </div>
        </nav>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  navigation: getNavigation(state)
});

export default connect(mapStateToProps)(Dashboard);
