import React, { useEffect, useState, Fragment } from 'react';
import { connect } from "react-redux";
import { Operation } from '../../reducer/complaints/complaints';
import {
  getComplaints,
  getComplaintsVideos,
  getIsLoadedComplaints
} from '../../reducer/complaints/selectors';
import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import Video from '../../components/Video/Video';
import classes from './Complaints.module.scss';

const Complaints = (props) =>  {
  const {complaints,  complaintsVideos, fetchComplaints, patchComplaint, isLoadedComplaints} = props;

  useEffect(() => {
    fetchComplaints(50)
  }, [])

  const handleComplaintsAction = async (pubId, solution) => {
    await complaints.filter(complaint => {
      if(complaint.publication_id === pubId) {
        patchComplaint(complaint.complaint_id, solution)
      }
    })
  }

  console.log('complaints: ', complaints)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.publications}>
          {complaintsVideos.map((publication) => (
            <Video
              key={publication.id}
              publication={publication}
              countInLine={4}
              isComplaintsVideo
              handleComplaintsAction={handleComplaintsAction}
            />
          ))}
          {isLoadedComplaints && (
            <Fragment>
              <Backdrop open={isLoadedComplaints} className={classes.backdrop}/>
              <CircularProgress color="primary" size={80} className={classes.loader} />
            </Fragment>
          )}
        </div>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  complaints: getComplaints(state),
  complaintsVideos: getComplaintsVideos(state),
  isLoadedComplaints: getIsLoadedComplaints(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchComplaints: (limit) => {
    dispatch(Operation.fetchComplaints(limit))
  },
  patchComplaint: (id, solution) => {
    dispatch(Operation.patchComplaint(id, solution))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Complaints);
