import React from 'react';
import { Link } from "react-router-dom";
import { AppRoute } from "../../constants";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { AccountCircle, ExitToApp } from "@material-ui/icons";
import classes from './Header.module.scss';

const Header = ({ activePage, admin }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <header className={classes.root}>
      <div className={classes.titles}>
        <h1 className={classes.title}>Trumpline</h1>
        <h2 className={classes.activePage}>{activePage}</h2>
      </div>
      <div className={classes.actions}>
        <p>{admin.full_name || admin.email}</p>
        <IconButton color="inherit" onClick={handleClick}>
          <AccountCircle />
        </IconButton>
        <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem>
            <Link className={classes.link} to={AppRoute.ADMIN_PROFILE} onClick={handleClose}>
              Профиль
            </Link>
          </MenuItem>
          <MenuItem>
            <Link className={classes.link} to={AppRoute.LOGOUT} onClick={handleClose}>
              <span>Выйти</span>
              <ExitToApp/>
            </Link>
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
