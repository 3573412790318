import React from 'react';
import { Button, FormControl, MenuItem, Select } from '@material-ui/core';
import { Autorenew as ReloadIcon, Sort as SortIcon } from '@material-ui/icons';
import classes from './Actions.module.scss';

const Actions = ({ publicationsState, isLoaded, setPublicationsState, onRefresh }) => {
  const { sortBy, sortDir } = publicationsState;
  const cls = [classes.sortSelect, classes[sortDir]]

  const handleRequestSort = (type) => {
    const isAsc = sortBy === type && sortDir === 'asc';

    setPublicationsState({
      ...publicationsState,
      sortDir: isAsc ? 'desc' : 'asc',
      sortBy: type
    })
  };

  return (
    <div className={classes.root}>
      <div className={classes.sort}>
        <p className={classes.sortTitle}>Сортировать:</p>
        <FormControl size='small'>
          <Select variant="outlined" value={sortBy} className={cls.join(' ')} disabled={isLoaded}>
            <MenuItem
              value='date' onClick={() => handleRequestSort('date')}
              className={classes[sortDir]}
            >
              По дате
              {sortBy === 'date' && <SortIcon fontSize='small' />}
            </MenuItem>
            <MenuItem
              value='views' onClick={() => handleRequestSort('views')}
              className={classes[sortDir]}
            >
              По просмотрам
              {sortBy === 'views' && <SortIcon fontSize='small' />}
            </MenuItem>
            <MenuItem
              value='popularity' onClick={() => handleRequestSort('popularity')}
              className={classes[sortDir]}
            >
              По популярности
              {sortBy === 'popularity' && <SortIcon fontSize='small' />}
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <Button onClick={onRefresh} disabled={isLoaded} className={classes.reload}>
        <ReloadIcon className={classes.icon} />
        Обновить
      </Button>
    </div>
  );
};

export default Actions;
