import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createAPI } from "./api.js";
import thunk from "redux-thunk";
import reducer from "./reducer/reducer";
import {ActionCreator} from "./reducer/auth/auth.js";

const api = createAPI();

export const store = createStore(
  reducer,
  composeWithDevTools(
      applyMiddleware(thunk.withExtraArgument(api))
  )
);

store.dispatch(ActionCreator.checkAuth());
