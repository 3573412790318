import React, { useEffect, useState } from 'react';
import classes from './Filters.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Chip, CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { useDebounce } from '../../../utils';

const Filters = (props) => {
  const {
    filters, users, tags, isLoaded, dayNow, isLoadedResources,
    setFilters, fetchUsers, fetchTags,
    handleReset, handleFilter
  } = props;
  const [open, setOpen] = useState({ users: false, tags: false });
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [inputUser, setInputUser] = useState('');
  const [optionsTags, setOptionsTags] = useState([]);
  const [inputTags, setInputTags] = useState('');

  const debouncedInputUser = useDebounce(inputUser, 700);
  const debouncedInputTags = useDebounce(inputTags, 700);

  useEffect(() => {
    if (debouncedInputUser.length > 2) {
      fetchUsers(100, 'created_at', 'asc', filters.userBy, debouncedInputUser);
    }
  }, [fetchUsers, debouncedInputUser]);

  useEffect(() => {
    setOptionsUsers(users.map((user) => user[filters.userBy]));
  }, [users]);

  useEffect(() => {
    setOptionsUsers([]);
    setInputUser('');
  }, [filters.userBy]);

  useEffect(() => {
    if (debouncedInputTags.length > 0) {
      fetchTags(100, debouncedInputTags)
    }
  }, [fetchTags, debouncedInputTags]);

  useEffect(() => {
    setOptionsTags(tags.map((tag) => tag.name));
  }, [tags]);

  const isDateValid = () => (filters.dateEnd && filters.dateStart) || (!filters.dateEnd && !filters.dateStart)

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Фильтры</h2>
      <Autocomplete
        multiple filterSelectedOptions
        open={open.tags}
        onOpen={() => setOpen({ ...open, tags: true })}
        onClose={() => setOpen({ ...open, tags: false })}
        options={optionsTags}
        value={filters.tags}
        onChange={(evt, newValue) => {
          setFilters({ ...filters, tags: newValue })
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params} variant="outlined" label="Tags" className={classes.filtersSelect}
            onChange={evt => setInputTags(evt.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoadedResources.tags ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <div className={classes.user}>
        <FormControl>
          <Select
            value={filters.userBy}
            onChange={(evt) => {
              setFilters({ ...filters, userBy: evt.target.value, user: null })
            }}
            variant="outlined"
          >
            <MenuItem value='username'>По никнейму</MenuItem>
            <MenuItem value='email'>По почте</MenuItem>
            <MenuItem value='phone'>По телефону</MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          options={optionsUsers}
          open={open.users}
          onOpen={() => setOpen({ ...open, users: true })}
          onClose={() => setOpen({ ...open, users: false })}
          getOptionSelected={(option, value) => option === value}
          getOptionLabel={(option) => option}
          value={filters.user}
          onChange={(evt, newValue) => {
            setFilters({ ...filters, user: newValue })
          }}
          renderInput={(params) => (
            <TextField
              {...params} label="Пользователь" variant="outlined"
              onChange={evt => setInputUser(evt.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadedResources.users ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>

      <div className={classes.geo}>
        <TextField
          variant='outlined' label="Страна" type='search'
          value={filters.country}
          onChange={(evt) => {setFilters({...filters, country: evt.target.value})}}
        />
        <TextField
          variant='outlined' label="Город" type='search'
          value={filters.city}
          onChange={(evt) => {setFilters({...filters, city: evt.target.value})}}
        />
      </div>

      <div className={classes.date}>
        <div>
          <span className={classes.label}>От</span>
          <TextField
            variant='outlined' type="date" fullWidth
            value={filters.dateStart}
            onChange={(evt) => setFilters({ ...filters, dateStart: evt.target.value})}
            inputProps={{ max: filters.dateEnd || dayNow }}
            error={!isDateValid() && !filters.dateStart}
          />
        </div>
        <div>
          <span className={classes.label}>До</span>
          <TextField
            variant='outlined' type="date" fullWidth
            value={filters.dateEnd}
            onChange={(evt) => setFilters({ ...filters, dateEnd: evt.target.value})}
            inputProps={{ max: dayNow, min: filters.dateStart }}
            error={!isDateValid() && !filters.dateEnd}
          />
        </div>
      </div>

      <div className={classes.buttons}>
        <Button variant="outlined" onClick={handleFilter} disabled={isLoaded || !isDateValid()}>Применить</Button>
        <Button variant="outlined" onClick={handleReset}>Сбросить</Button>
      </div>
    </div>
  );
};

export default Filters;
