import {useEffect, useState} from 'react';
import dayjs from "dayjs";
import 'dayjs/locale/ru';

dayjs.locale('ru');

export const getDate = (timestamp, format = 'D MMMM YYYY') => timestamp ? dayjs.unix(timestamp).format(format) : '';

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
      const handler = setTimeout(() => setDebouncedValue(value), delay);
      return () => clearTimeout(handler);
    },[value]);

  return debouncedValue;
}

export const getHeaders = () => {
  const token = localStorage.getItem('auth');
  const { access_token } = token ? JSON.parse(token) : {access_token: null};
  return { headers: {
    Authorization: `Bearer ${access_token}`
  }}
}

export const getCommaString = (arr, key = 'name') => {
  const reducer = (prevVal, curVal) => `${prevVal[key]}, ${curVal[key]}`;
  const isHaveKey = (item => {
    return item.hasOwnProperty(key);
  });
  return arr?.filter(isHaveKey)
    .reduce(reducer);
};
