import React from 'react';
import { connect } from "react-redux";
import { Operation as AuthOperation} from "../../reducer/auth/auth";
import { AppBar, Paper, Tab, Tabs } from '@material-ui/core';
import AuthForm from './AuthForm/AuthForm';
import ConfirmForm from './ConfirmForm/ConfirmForm';
import classes from './Auth.module.scss';

const Auth = ({ auth, recovery, confirmCreate, confirmRecovery }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChangeTabs = (evt, activeTab) => setActiveTab(activeTab);

  const handleConfirm = async (isCreate, data) => {
    const res = isCreate ? await confirmCreate(data) : await confirmRecovery(data);
    res && handleChangeTabs({}, 0);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default" className={classes.tabs}>
          <Tabs value={activeTab} onChange={handleChangeTabs} indicatorColor="primary" textColor="primary" variant="fullWidth">
            <Tab label="Войти" />
            <Tab label="Подтвердить" />
          </Tabs>
        </AppBar>
        {activeTab === 0 ? <AuthForm auth={auth} recovery={recovery}/> : <ConfirmForm confirm={handleConfirm}/>}
      </Paper>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  auth: (data) => dispatch(AuthOperation.login(data)),
  recovery: (email) => dispatch(AuthOperation.recovery(email)),
  confirmCreate: (data) => dispatch(AuthOperation.confirmCreate(data)),
  confirmRecovery: (data) => dispatch(AuthOperation.confirmRecovery(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
