import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Select, TextField } from '@material-ui/core';
import { PersonAdd as AddIcon } from '@material-ui/icons';
import { Role } from '../../../constants';
import classes from './EmployeesHeader.module.scss';
import is from 'is_js';

const EmployeesHeader = ({ addEmployee }) => {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({ role: Role.ADMIN, email: '' });

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setFormData({ role: Role.ADMIN, email: '' });
    setOpen(false);
  };

  const handleChange = (evt, field) => setFormData({ ...formData, [field]: evt.target.value});

  const handleSubmit = async () => {
    if (await addEmployee(formData)) {
      handleClose();
    }
  }

  return (
    <div className={classes.root}>
      <Button onClick={handleClickOpen}>
        <AddIcon />
        <span className={classes.addBtnLabel}>Добавить сотрудника</span>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={classes.dialog}>
          <TextField required autoFocus fullWidth variant="outlined" margin="normal" label="Email"
                     value={formData.email}
                     onChange={(evt) => handleChange(evt, 'email')} />
          <Select fullWidth  variant="outlined" className={classes.select}
                  value={formData.role}
                  onChange={(evt) => handleChange(evt, 'role')}>
            <MenuItem value={Role.ADMIN}>Администратор</MenuItem>
            <MenuItem value={Role.MODERATOR}>Модератор</MenuItem>
            <MenuItem value={Role.MANAGER}>Контент-менеджер</MenuItem>
          </Select >
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Отмена</Button>
          <Button onClick={handleSubmit} color="primary" disabled={!is.email(formData.email)}>
            Добавить сотрудника
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmployeesHeader;
