import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { getDate } from '../../utils';
import { getColumns, getEmployees, getEmployeesState } from '../../reducer/employee/selectors';
import { ActionCreator, Operation as EmployeesOperation } from '../../reducer/employee/employee';
import {
  Button, Dialog, DialogActions, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableHead,
  TableRow, Tooltip,
} from '@material-ui/core';
import { AccountBox as AccountBoxIcon, Block as BlockIcon, NavigateBefore, NavigateNext } from '@material-ui/icons';
import classes from './Employees.module.scss';
import EmployeesHeader from './EmployeesHeader/EmployeesHeader';
import { Role } from '../../constants';
import { getAppError, getAppSuccess } from '../../reducer/app/selectors';

const Employees = (props) => {
  const { fetchEmployees, employees, columns, banEmployee, setEmployeesState, employeesState, addEmployee, error, success } = props;
  const { page, rowsPerPage } = employeesState;

  useEffect(() => fetchEmployees(100), [fetchEmployees]);

  const handleChangePage = (newPage) => {
    if (newPage < 0 || employees.length <= newPage * rowsPerPage) return;
    setEmployeesState({ ...employeesState, page: newPage});
  }

  const handleBanEmployee = async (id) => {
    if (await banEmployee(id)) {
      fetchEmployees(100);
      setDialogOptions({ open: false, id: null });
    }
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, employees.length - page * rowsPerPage);

  const [dialogOptions, setDialogOptions] = useState({ open: false, id: null });
  const handleClickOpen = (id) => setDialogOptions({ open: true, id });
  const handleClose = () => setDialogOptions({ open: false, id: null });

  return (
    <div className={classes.root}>
      <EmployeesHeader addEmployee={addEmployee} error={error} success={success}/>
      <Paper className={classes.paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {columns.map((headCell) => <TableCell key={headCell.field}>{headCell.label}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id} hover>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.full_name}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{getDate(user.created_at)}</TableCell>
                  <TableCell>{user.is_active ? 'Да' : 'Нет'}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <div className={classes.actions}>
                      <Tooltip title='Профиль' placement='bottom'>
                        <NavLink to={`/employees/${user.id}`} className={classes.link}>
                          <AccountBoxIcon/>
                        </NavLink>
                      </Tooltip>
                      {(user.role !== Role.ADMIN && user.is_active) && (
                        <div className={classes.block}>
                          <Tooltip title='Заблокировать' placement='bottom'>
                            <button type='button' className={classes.banBtn} onClick={() => handleClickOpen(user.id)}>
                              <BlockIcon/>
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            }
            {emptyRows > 0 && <TableRow style={{ height: 37 * emptyRows }}><TableCell colSpan={8} /></TableRow>}
          </TableBody>
        </Table>
        <div className={classes.pagination}>
          <IconButton color="primary" onClick={() => handleChangePage(page - 1)} disabled={page === 0}>
            <NavigateBefore />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleChangePage(page + 1)}
            disabled={employees.length <= (page + 1) * rowsPerPage}
          >
            <NavigateNext />
          </IconButton>
        </div>
      </Paper>
      <Dialog open={dialogOptions.open} onClose={handleClose} >
        <DialogTitle>Вы точно хотите удалить этого менеджера?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Нет</Button>
          <Button onClick={() => handleBanEmployee(dialogOptions.id)} color="primary">Да</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  employees: getEmployees(state),
  columns: getColumns(state),
  employeesState: getEmployeesState(state),
  error: getAppError(state),
  success: getAppSuccess(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployees: (limit) => dispatch(EmployeesOperation.fetchEmployees(limit)),
    banEmployee: (id) => dispatch(EmployeesOperation.banEmployee(id)),
    setEmployeesState: (state) => dispatch(ActionCreator.setEmployeesState(state)),
    addEmployee: (data) => dispatch(EmployeesOperation.addEmployee(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
