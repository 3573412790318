import axios from "axios";

export const createAPI = () => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'https://api.trueline.io/api/v1/'
      : 'https://dev.trumpline.itnap.ru/api/v1/'
    }`
  });

  const onSuccess = (response) => response;

  const onFail = (err) => {
    throw err;
  };

  api.interceptors.response.use(onSuccess, onFail);

  return api;
};
