import React from "react";
import { connect } from "react-redux";
import {getAdmin, getAuthorizationStatus} from "../../reducer/auth/selectors";
import { getActivePage, getAppError, getAppSuccess, getNavigation } from '../../reducer/app/selectors';
import Drawer from "../../components/Navigation/Drawer/Drawer";
import Header from "../../components/Header/Header";
import classes from './Layout.module.scss';
import { Titles } from "../../constants";
import Notification from '../../components/Notification/Notification';

const Layout = ({ isAuthenticated, navigation, children, activePage, admin, error, success }) => {
  const title = Titles[window.location.pathname]
  return (
    <div className={classes.Layout}>
      { isAuthenticated && <Header activePage={ title || activePage } admin={admin} />}
      <main>
        { isAuthenticated && (
          <Drawer
            isAuthenticated={ isAuthenticated }
            menu={navigation}
          />)}
        { children }
      </main>
      {error && <Notification type='error'>{error}</Notification> }
      {success && <Notification type='success'>{success}</Notification> }
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: getAuthorizationStatus(state),
  activePage: getActivePage(state),
  navigation: getNavigation(state),
  admin: getAdmin(state),
  error: getAppError(state),
  success: getAppSuccess(state)
});

export default connect(mapStateToProps)(Layout);
