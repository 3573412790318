import React, { useState } from 'react';
import classes from '../Auth.module.scss';
import { Button, TextField } from '@material-ui/core';
import is from 'is_js';

const AuthForm = ({ auth, recovery }) => {
  const [authData, setAuthData] = useState({ email: '', password: '' });

  const handleAuthChange = (evt, field) => {
    setAuthData({ ...authData, [field]: evt.target.value})
  }

  const formIsValid = () => {
    return is.email(authData.email) && authData.password.length >= 8;
  }

  return (
    <form className={ classes.form }>
      <TextField
        required fullWidth variant="outlined" label="Email" className={classes.input}
        value={authData.email}
        onChange={(evt) => handleAuthChange(evt, 'email')}
      />
      <TextField
        required variant="outlined" label="Password" type="password" className={classes.input}
        value={authData.password}
        onChange={(evt) => handleAuthChange(evt, 'password')}
      />
      <div className={classes.buttons}>
        <Button fullWidth variant="outlined" onClick={ () => auth(authData) } disabled={!formIsValid()}>
          Войти
        </Button>
        <Button fullWidth variant="outlined" onClick={ () => recovery(authData.email) } disabled={!is.email(authData.email)}>
          Восстановить пароль
        </Button>
      </div>
    </form>
  );
};

export default AuthForm;
