import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classes from './EmployeeProfile.module.scss';
import { Operation } from '../../reducer/employee/employee';
import { getEmployee } from '../../reducer/employee/selectors';
import { Button, FormControl, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { getDate } from '../../utils';
import InputMask from 'react-input-mask';
import is from 'is_js';
import { Operation as AuthOperation } from '../../reducer/auth/auth';
import { Role } from '../../constants';
import { getAdmin } from '../../reducer/auth/selectors';

const EmployeeProfile = ({ fetchEmployee, employee, editEmployee, recovery, match, admin }) => {
  const [employeeData, setEmployeeData] = useState({id: '', full_name: '', role: '', phone: '', email: ''});

  useEffect(() => {
    fetchEmployee(match.params.id).then((data) => {
      if (data) {
        setEmployeeData(data);
      }
    })
  }, [fetchEmployee, match.params.id]);

  const handleChange = (evt, field) => {
    setEmployeeData({ ...employeeData, [field]: evt.target.value})
  }

  const formIsValid = () => {
    const phoneRegexp = /\+7 \(\d{3}\) \d{3} \d{2} \d{2}/g;
    const isPhoneValid = employeeData.phone ? phoneRegexp.test(employeeData.phone) : true;
    return is.email(employeeData.email) && isPhoneValid;
  }

  const isChange = () => {
    return JSON.stringify(employee) === JSON.stringify(employeeData) || !formIsValid();
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <header className={classes.header}>
          <h2>{employeeData.full_name || employeeData.email}</h2>
          <h3>{employeeData.role}</h3>
        </header>

        <form className={classes.form}>
          <div className={classes.info}>
            <TextField
              className={classes.input} label="ID" variant="outlined" size="small"
              value={employeeData.id} InputProps={{readOnly: true}}
            />
            <TextField
              className={classes.input} label="Зарегистрирован" variant="outlined" size="small"
              value={getDate(employeeData.created_at)} InputProps={{readOnly: true}}
            />
            { employeeData.role !== Role.ADMIN && admin.role === Role.ADMIN && (
              <FormControl className={classes.input} size='small' variant="outlined">
                <Select
                  value={employeeData.role}
                  onChange={(evt) => handleChange(evt, 'role')}
                >
                  <MenuItem value='moderator'>Модератор</MenuItem>
                  <MenuItem value='manager'>Контент-менеджер</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>

          <TextField
            className={classes.input} label="ФИО" variant="outlined"
            value={employeeData.full_name} inputProps={{maxLength: 100}}
            onChange={(evt) => handleChange(evt, 'full_name')}
          />
          <InputMask
            mask='+7 (999) 999 99 99'
            value={employeeData.phone} onChange={(evt) => handleChange(evt, 'phone')}
          >
            {() => <TextField className={classes.input} label="Телефон" variant="outlined"/>}
          </InputMask>
          <TextField
            className={classes.input} label="Почта" variant="outlined" required
            error={!is.email(employeeData.email)}
            value={employeeData.email}
            onChange={(evt) => handleChange(evt, 'email')}
          />
          <div className={classes.buttons}>
            <Button
              variant="outlined" color="primary"
              disabled={isChange() || !employeeData.id}
              onClick={() => editEmployee(employeeData)}
            >
              Сохранить изменения
            </Button>
            <Button
              variant="outlined" color="primary"
              disabled={!is.email(employeeData.email) || !employeeData.id}
              onClick={() => recovery(employeeData.email)}
            >
              Восстановить пароль
            </Button>
          </div>
        </form>
      </Paper>
      <Paper className={classes.paper2}>

      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  employee: getEmployee(state),
  admin: getAdmin(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmployee: (id) => dispatch(Operation.fetchEmployee(id)),
  editEmployee: (data) => dispatch(Operation.editEmployee(data)),
  recovery: (email) => dispatch(AuthOperation.recovery(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile);
