import React, { useState } from 'react';
import classes from '../Auth.module.scss';
import { Button, TextField } from '@material-ui/core';

const ConfirmForm = ({ confirm }) => {
  const [confirmData, setConfirmData] = useState({ token: '', password: '' });

  const handleConfirmChange = (evt, field) => {
    setConfirmData({ ...confirmData, [field]: evt.target.value})
  }

  return (
    <form className={ classes.form } autoComplete='off'>
      <input type='password' className={classes.hide}/>
      <TextField
        required fullWidth variant="outlined" label="Token" className={classes.input}
        value={confirmData.token}
        onChange={(evt) => handleConfirmChange(evt, 'token')}
      />
      <TextField
        required variant="outlined" label="New Password" type="password" className={classes.input}
        value={confirmData.password}
        onChange={(evt) => handleConfirmChange(evt, 'password')}
      />
      <div className={classes.buttons}>
        <Button
          fullWidth variant="outlined"
          onClick={() => confirm(true, confirmData)}
          disabled={!confirmData.token || confirmData.password.length < 8}
        >
          Создать
        </Button>
        <Button
          fullWidth variant="outlined"
          onClick={() => confirm(false, confirmData)}
          disabled={!confirmData.token || confirmData.password.length < 8}
        >
          Восстановить
        </Button>
      </div>
    </form>
  );
};

export default ConfirmForm;
