import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import {ActionCreator} from "../../reducer/auth/auth";

class Logout extends Component {
  componentDidMount() {
    this.props.logout()
  }

  render() {
    return <Redirect to={'/auth'}/>
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(ActionCreator.requireAuthorization(false))
  }
};

export default connect(null, mapDispatchToProps)(Logout);
