import { getHeaders } from '../../utils';
import { ActionCreator as AppActions } from '../app/app';
import NameSpace from '../name-space';

const initialState = {
  complaints: [],
  complaintsVideos: [],
  // TODO добавить action для установки лимита и пагинации
  complaintVideosLimit: 50,
  isLoadedComplaints: false,
  isLoadedComplaintsVideos: false
}

const ActionType = {
  LOAD_COMPLAINTS: 'LOAD_COMPLAINTS',
  LOAD_COMPLAINTS_VIDEO: 'LOAD_COMPLAINTS_VIDEO',
  SET_LOADED_COMPLAINTS: 'SET_LOADED_COMPLAINTS',
  SET_LOADED_COMPLAINTS_VIDEOS: 'SET_LOADED_COMPLAINTS_VIDEOS',
  SET_COMPLAINT_STATUS: 'SET_COMPLAINT_STATUS',
  SET_COMPLAINT_VIDEOS_LIMIT: 'SET_COMPLAINT_VIDEOS_LIMIT'
};

const ActionCreator = {
  loadComplaints: (complaints) => {
    return {
      type: ActionType.LOAD_COMPLAINTS,
      payload: { complaints }
    }
  },
  loadComplaintsVideo: (complaintsVideo) => {
    return {
      type: ActionType.LOAD_COMPLAINTS_VIDEO,
      payload: { complaintsVideo }
    }
  },
  setLoadedComplaints: (isLoadedComplaints) => {
    return {
      type: ActionType.SET_LOADED_COMPLAINTS,
      payload: isLoadedComplaints
    }
  },
  setLoadedComplaintsVideos: (isLoadedComplaintsVideos) => {
    return {
      type: ActionType.SET_LOADED_COMPLAINTS_VIDEOS,
      payload: isLoadedComplaintsVideos
    }
  },
};

const Operation = {
  fetchComplaints: (limit) => (dispatch, getState, api) => {
    dispatch(ActionCreator.setLoadedComplaints(true));
    return api.get(`/admin/complaints?limit=${limit}`, getHeaders())
      .then(
        (data) => {
          const complaints = data.data.complaints;
          dispatch(ActionCreator.loadComplaints(complaints));

          let complaintsPublications = [];
          const publicationIds = new Set();

          complaints.forEach(async (complaint) => {
            if(complaint.status === 'new') {
              publicationIds.add(complaint.publication_id)
            }
          })

          Array.from(publicationIds).forEach(async (id) => {
            const publication = await api.get(`/publications/${id}`, getHeaders());
            complaintsPublications = [...complaintsPublications, publication.data]
            dispatch(ActionCreator.loadComplaintsVideo(complaintsPublications))
          })

          dispatch(ActionCreator.setLoadedComplaints(false));
        },
        (err) => {
          dispatch(AppActions.dataError(
            err,
            Operation.fetchComplaints, [limit],
            'Не удалось загрузить жалобы'
          ))
          dispatch(ActionCreator.setLoadedComplaints(false));
        }
      )
  },
  patchComplaint: (id, solution) => (dispatch, getState, api) => {
    console.log('id: ', id)
    return api.patch(`/admin/complaints/${id}`, {status: solution},  getHeaders())
      .then(
        (data) => {
          const succText = `Жалоба успешно ${solution === 'rejected' ? 'отклонена' : 'обработана. Видео заблокировано'}`
          dispatch(AppActions.dataSuccess(succText));
          // TODO подстягивать лимит из state
          dispatch(Operation.fetchComplaints(50))
        },
        (err) => {
          dispatch(AppActions.dataError(err, Operation.patchComplaint, [id, solution]));
          // TODO подстягивать лимит из state
          dispatch(Operation.fetchComplaints(50))
        }
      )
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload.complaints,
      };
    case ActionType.LOAD_COMPLAINTS_VIDEO:
      return {
        ...state,
        complaintsVideos: action.payload.complaintsVideo
      }
    default:
      return state;
  }
};

export {ActionCreator, ActionType, reducer, Operation};
